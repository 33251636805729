import React,{Component} from 'react';
import 'font-awesome/css/font-awesome.min.css';
import big_logo from '../../Assets/Images/recovery-hub-payment.png';
import  LoadingSpinner from './Spinner';
import axios from 'axios';
import Layout from '../Layout/Layout';
import { Link } from 'react-router-dom'
import * as constants from '../Global/Global';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  Table } from 'react-bootstrap'
import Popup from './PopupNew';
toast.configure();


class PaymentMethods extends Component{
    constructor(props) {
      super(props);
      this.state = {
          claimDetails :{
          claim_id: '',
          invoiceNumber: '',
          amount: '',
          amount_collected:0,
          rem_balance:0,
          validForm:false,
          invoiceValid: false,
          amountValid: false,
          alert:'' ,
          status:'',
          departmentName:'',
          creditCardFee:0,
          echeckFee:0,
          showHide:false,
      },
      formToken:{
        creditCard:'',
        eCheck:''
      }

    };
  }
  /*
  This willcredit card button click
  */
  handleCreditClick = ()=>{
     
    // this.props.history.push('../../paymentform/'+this.state.claimDetails.invoice_type+'/'+this.state.claimDetails.invoiceNumber+'/credit_card');

    this.props.history.push({
      pathname: '../../paymentform/'+this.state.claimDetails.invoice_type+'/'+this.state.claimDetails.invoiceNumber+'/credit_card',          
      state: { payment_amount: this.props?.location?.state?.payment_amount, payment_plan: this.props?.location?.state?.payment_plan }
    });  
  }
  /*
  This will credit echeck button click
  */
  handleEcheckClick = ()=>{
      
    this.setState({ showHide: true });   
  }
  /*
  This will mail a check button click
  */
  handlemailapaperClick = ()=>{
     
    //this.props.history.push('../../mailacheck/'+this.state.claimDetails.invoice_type+'/'+this.state.claimDetails.invoiceNumber);

    this.props.history.push({
      pathname: '../../mailacheck/'+this.state.claimDetails.invoice_type+'/'+this.state.claimDetails.invoiceNumber,          
      state: { payment_amount: this.props?.location?.state?.payment_amount }
    });      

  }

  handleVerify = ()=> { 
    this.setState({ showHide: false });
    this.props.history.push({
      pathname: '../../paymentHelp/'+this.state.claimDetails.invoice_type+'/'+this.state.claimDetails.invoiceNumber+'/e_check',          
      state: { payment_amount: this.props?.location?.state?.payment_amount }
    });
}

handleModalShowHide = ()=> { 
    this.setState({ showHide: !this.state.showHide }) 
}

  /*
  This will life cycle method  handle the data fetch from DB
  */
  componentDidMount() {
    
    const invoice = this.props.match.params.id;
    const invoice_type  = this.props.match.params.invoice_type ? this.props.match.params.invoice_type :null;    
    //console.log(this.props);
    if ( typeof this.props?.location?.state?.payment_amount == 'undefined' ) {
      this.props.history.push({
        pathname: '/'        
      }); 
    }
    const axiosConfig = { params: {a: 0, payment_plan: this.state.payment_plan}};
    if ( typeof this.props?.location?.state?.payment_amount != 'undefined' ) {
      axiosConfig.params.a = Number(this.props?.location?.state?.payment_amount);
      axiosConfig.params.payment_plan = this.props?.location?.state?.payment_plan;
    }
    
    //console.log(process.env.PUBLIC_URL);

    if(invoice_type !== 'inspection') //For claim payments
    { 
              axios.get(constants.CLAIMDETAILS+'claim/'+invoice,axiosConfig)
              .then((response)=> {
              const res =response.data.result;
              if( res.length === 0)
              {
                alert( 'No claim found with this invoice number');
              }
              else if(res.claim.status =='Payment Received')
                  {
                   
                  toast.error('The specified invoice is already paid.'); 
                  this.props.history.push('/'); 

                  }
              else
              {
                  const invoiceData = res.claim ;
                  const currentClaimDetails = {...this.state.claimDetails};
                  currentClaimDetails.departmentName = invoiceData.name;
                  currentClaimDetails.creditCardFee =0;
                  currentClaimDetails.echeckFee = 0;
                  currentClaimDetails.amount = parseFloat(invoiceData.amount_invoiced).toFixed(2);
                  currentClaimDetails.amount_collected = parseFloat(invoiceData.amount_collected).toFixed(2);
                  let invoiceTotal = parseFloat(invoiceData.amount_invoiced);
                  let amountCollected = parseFloat(invoiceData.amount_collected);
                  let difference = 0;
                    // Check for NaN and null values
                    if (isNaN(invoiceTotal) || isNaN(amountCollected)) {                      
                      invoiceTotal = parseFloat(invoiceData.amount_invoiced).toFixed(2);
                      amountCollected = 0;
                    } 
                    difference = parseFloat(invoiceTotal) - parseFloat(amountCollected);                      
                   
                  currentClaimDetails.rem_balance =  difference;                 
                  currentClaimDetails.amountValid =true;
                  currentClaimDetails.alert = '';
                  currentClaimDetails.status = invoiceData.status;
                  currentClaimDetails.invoiceNumber = invoiceData.invoice_number;
                  currentClaimDetails.invoiceValid = true;
                  currentClaimDetails.validForm = true;
                  currentClaimDetails.claim_id = invoiceData.original_run_id;
                  currentClaimDetails.invoice_type ='claim';
                  currentClaimDetails.payment_amount = parseFloat(1 * this.props?.location?.state?.payment_amount ).toFixed(2);
                  currentClaimDetails.payment_plan = this.props?.location?.state?.payment_plan;  
                  currentClaimDetails.card_fee = parseFloat(invoiceData.card_fee) * 1;
                  currentClaimDetails.echeck_fee = parseFloat(invoiceData.echeck_fee) * 1;                  

                  this.setState({claimDetails:currentClaimDetails});

               }
              })
              .catch(function (error) {
                //console.log(error);
              });
             

    }
    else //For new inspection payments
    {


      axios.get(constants.INSPECTIONDETAILS+invoice,axiosConfig)
              .then((response)=> {
              const res =response.data.result;
              if( !res)
              {                
                toast.error('No Inspection/Claim invoice found for this invoice number.');  
              }
              else if(res.status =='Paid')
              {                
                 toast.error('The specified invoice is already paid.'); 
                 this.props.history.push('/'); 

              }
              else
              {
                 const invoiceData = res ;
                 //console.log(invoiceData.department.name);
                 const currentClaimDetails = {...this.state.claimDetails};
                  currentClaimDetails.departmentName = invoiceData.department.name;
                
                  currentClaimDetails.amount = parseFloat(invoiceData.invoice_total).toFixed(2);
                  currentClaimDetails.invoice_subtotal = parseFloat(invoiceData.invoice_subtotal).toFixed(2);
                  let invoiceTotal = parseFloat(invoiceData.invoice_total);
                  let amountCollected = parseFloat(invoiceData.amount_collected);
                  let difference = 0;
                    // Check for NaN and null values
                    if (isNaN(invoiceTotal) || isNaN(amountCollected)) {
                      invoiceTotal = parseFloat(invoiceData.invoice_total).toFixed(2);
                      amountCollected = 0;
                    } 
                    difference = parseFloat(invoiceTotal) - parseFloat(amountCollected);                      
                   
                  currentClaimDetails.rem_balance =  difference;
                  
                  currentClaimDetails.credit_surcharge = parseFloat(invoiceData.department.credit_surcharge);
                  currentClaimDetails.echeck_surcharge = parseFloat(invoiceData.department.echeck_surcharge);
                  currentClaimDetails.check_surcharge = parseFloat(invoiceData.department.check_surcharge);


                  currentClaimDetails.card_fee = parseFloat(invoiceData.card_fee) * 1;
                  currentClaimDetails.echeck_fee = parseFloat(invoiceData.echeck_fee) * 1;
                  currentClaimDetails.check_fee = parseFloat(invoiceData.check_fee) * 1;



                  currentClaimDetails.late_fee = parseFloat(invoiceData.late_fee) * 1;
                  currentClaimDetails.invoice_type ='inspection';
                  
                  
                  
                  currentClaimDetails.amountValid =true;
                  currentClaimDetails.alert = '';
                  currentClaimDetails.status = invoiceData.status;
                  currentClaimDetails.invoiceNumber = invoice;
                  currentClaimDetails.invoiceValid = true;
                  currentClaimDetails.validForm = true;
                  currentClaimDetails.claim_id = invoiceData.original_run_id;
                  currentClaimDetails.payment_amount = parseFloat(1 * this.props?.location?.state?.payment_amount ).toFixed(2);
                  this.setState({claimDetails:currentClaimDetails});

               }
              })
              .catch(function (error) {
                //console.log(error);
              });
            };


    }
  /*
  This will handle the JSX Rendering
  */
  render ()
  {
      return (

        <Layout>
        <div className="custom-container">
        <Popup handleVerify={this.handleVerify}  handleModalShowHide={this.handleModalShowHide} showHide={this.state.showHide} />

                <div className="payment-wrap">
                    <div className="logo"><Link to="/"><img src={big_logo} alt=""/></Link></div>
                    <div className="pay-method">
                        <h1>{this.state.claimDetails.departmentName}</h1>
                        {! this.state.claimDetails.validForm ? <LoadingSpinner />:""} 
                         
                        {
                           this.state.claimDetails.invoice_type  == 'inspection' ?
                                 
                                 
                                <Table borderless    size="sm" responsive >
                                <tbody>
                                <tr>
                                 <td style={{textAlign:"center "}}>Invoice Amount</td>
                                  <td style={{textAlign:"left"}}>${this.state.claimDetails.invoice_subtotal}</td>
                                </tr>
                                {
                                this.state.claimDetails.late_fee ?  
                                <tr>
                                  <td style={{textAlign:"center "}}>Late Fee</td>
                                  <td style={{textAlign:"left"}}>${( 1 * this.state.claimDetails.late_fee ).toFixed(2)}</td>
                                </tr>
                                :null
                               }
                                </tbody>
                                <tfoot>
                                <tr style={{borderTop: "1px solid #ccc"}} >
                                    <td style={{textAlign:"center "}}>Total </td>
                                    <td style={{textAlign:"left"}}>${((1*this.state.claimDetails.late_fee)+(1*this.state.claimDetails.invoice_subtotal)).toFixed(2)}</td>
                                </tr>
                                </tfoot>
                                </Table>
                                 
                                :
                                <h3>Invoice Amount : ${(1*this.state.claimDetails.amount).toFixed(2)}</h3>                              

                        }
                        <h3>Remaining  Balance : ${(1*this.state.claimDetails.rem_balance).toFixed(2)}</h3>
                        <h3>Payment Amount : ${(1*this.state.claimDetails.payment_amount).toFixed(2)}</h3>
                        <div className="pay-option">
                        { (this.state.claimDetails.payment_plan!='ARB') ?
                            <div className="title">We have 2 easy payment options: (Click the one you prefer)</div>
                            : <div className="title">Click the payment option below</div>
                        }
                            <div className="content">
                                <ul>
                                    <li><button disabled={!this.state.claimDetails.invoiceNumber}  onClick={this.handleCreditClick} type="button" className="btn"  >
                                                Pay by Credit Card Online
                                    </button>                                  
                                      {
                                        this.state.claimDetails.invoice_type === 'inspection' && this.state.claimDetails.card_fee ? 
                                        <span><br/><small>Additional fee of ${( 1 * this.state.claimDetails.card_fee).toFixed(2)} applies</small></span>
                                        :null
                                      }                                     
                                     
                                    </li>
                                    { (this.state.claimDetails.payment_plan!='ARB') ?
                                    <li><button disabled={!this.state.claimDetails.invoiceNumber} type="button" onClick={this.handleEcheckClick} className="btn" >
                                   Pay by Electronic Check Online
                                    </button> 
                                      {
                                        this.state.claimDetails.invoice_type === 'inspection' && this.state.claimDetails.card_fee ? 
                                          <span><br/><small>Additional fee of ${( 1 * this.state.claimDetails.echeck_fee).toFixed(2)} applies</small></span>
                                          :null
                                      }
                                       
                                    </li>
                                    : null
                                  }
                                    {/* commented as per Steve comments */}
                                    {/* <li><button disabled={!this.state.claimDetails.invoiceNumber} onClick={this.handlemailapaperClick} type="button" className="btn"  >
                                          Mail a Paper Check
                                    </button>
                                    {
                                      this.state.claimDetails.check_fee?
                                       <span><br/><small>Additional fee of ${( 1 * this.state.claimDetails.check_fee).toFixed(2)} applies</small></span>
                                    :null
                                    } 
                                    </li> */}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </Layout>
      );

  };
}
export default PaymentMethods;
