import React,{Component} from 'react';

import 'font-awesome/css/font-awesome.min.css';
import big_logo from '../../Assets/Images/recovery-hub-payment.png';
import  LoadingSpinner from './Spinner';
import * as constants from '../Global/Global';
import axios from 'axios';
import { Link } from 'react-router-dom'
import Layout from '../Layout/Layout';
class PaymentMethods extends Component{
    constructor(props) {
      super(props);
      this.state = {
          claimDetails :{
          claim_id: '',
          amount: '',
          alert:'' ,
          departmentName:'',
          status:''
      }
    };
  }
  /*
  This will life cycle method  handle the data fetch from DB with claim status update
  */
  componentDidMount() {

    const invoice = this.props.match.params.id;
    const invoice_type = this.props.match.params.invoice_type;
   // console.log(invoice_type);
    axios.get(constants.CLAIMDETAILS+invoice_type+'/'+invoice)
    .then((response)=> {
    const res =response.data.result;
    //console.log(res[0].amount_invoiced);
     if(invoice_type !== 'inspection') //For claim payments
    { 
              if( res.length === 0)
              {
                alert( 'No claim found with this invoice number');
              }
              else
              {
                 const invoiceData = res.claim ;
                 const currentClaimDetails = {...this.state.claimDetails};
                  currentClaimDetails.amount = (1 * invoiceData.amount_invoiced).toFixed(2);;
                  currentClaimDetails.collectedAmount = (1 * invoiceData.amount_collected).toFixed(2);
                  currentClaimDetails.balanceAmount = (1 * invoiceData.amount_invoiced - 1 * invoiceData.amount_collected).toFixed(2);
                  currentClaimDetails.departmentName = invoiceData.name;
                  if(invoiceData.status === 'Payment Received')
                      currentClaimDetails.alert = 'Thank you for your payment for Invoice ID:  #'+invoiceData.invoice_number;
                      else {
                          currentClaimDetails.alert = 'Updating the payment status for the claim.......';
                          //var x = 0;
                          //const timer =  setInterval(() => {
                          const timeOut = setTimeout(() => {
                                  //console.log('Interval triggered');
                                  axios.get(constants.CLAIMDETAILS+invoice_type+'/'+invoice)
                                  .then((response)=> {
                                  const latest_res = response.data.result.claim;
                                  //if(latest_res.status === 'Payment Received')
                                        //{
                                            currentClaimDetails.alert = 'Thank you for your payment #'+latest_res.invoice_number;
                                            currentClaimDetails.status = latest_res.status;
                                            currentClaimDetails.download = true;
                                            currentClaimDetails.collectedAmount = (1 * latest_res.amount_collected).toFixed(2);
                                            currentClaimDetails.balanceAmount = (1 * latest_res.amount_invoiced - 1 * latest_res.amount_collected).toFixed(2);                                            
                                            this.setState({claimDetails:currentClaimDetails});
                                           // window.clearInterval(timer);
                                        //}
                                  });
                              //if (++x === 5) {
                                       // window.clearInterval(timer);
                                       // currentClaimDetails.alert = 'Failed to update the claim status#';
                                       // this.setState({claimDetails:currentClaimDetails});
                                        //window.clearInterval(timer);
                             // }
                          }, 35000);
                          //}, 4000);

                      }
                  currentClaimDetails.invoiceValid = true;
                  currentClaimDetails.status = invoiceData.status;
                  this.setState({claimDetails:currentClaimDetails});

               }
      }
      else
      {


            if( !res)
              {
                alert( 'No claim found with this invoice number');
              }
              else
              {
                 const invoiceData = res ;
                 const currentClaimDetails = {...this.state.claimDetails};
                  currentClaimDetails.amount = (1 * invoiceData.invoice_total).toFixed(2);;

                  currentClaimDetails.collectedAmount = (1 * invoiceData.amount_collected).toFixed(2);
                  currentClaimDetails.balanceAmount = (1 * invoiceData.invoice_total - 1 * invoiceData.amount_collected).toFixed(2);                  

                  currentClaimDetails.departmentName = invoiceData.department.name;
                  if(invoiceData.status === 'Payment Received')
                      currentClaimDetails.alert = 'Thank you for your payment for Invoice ID:  #'+invoiceData.display_id;
                      else {
                          currentClaimDetails.alert = 'Updating the payment status for the invoice.......';
                          var x = 0;
                          //const timer =  setInterval(() => {
                            const timeOut = setTimeout(() => {
                                  //console.log('Interval triggered');
                                  axios.get(constants.CLAIMDETAILS+invoice_type+'/'+invoice)
                                  .then((response)=> {
                                  const latest_res = response.data.result;
                                  //if(latest_res.status === 'Paid')
                                        //{  

 
                                              
                                               
                                             /* $download =false;
                                              if ($Department->allow_permits == 1) 
                                              $download =true;
                                              else if ($Department->allow_permits == 2) 
                                              $download =false;
                                              else if ($Invoice->send_certificate == 0) 
                                              $download =false;
                                              else if ($Invoice->send_certificate == 1) 
                                              $download =true;


                                              if($download == TRUE):
                                        // zco fix 24 05 start
                                      */
                                         

                                            currentClaimDetails.invoice_type = latest_res.invoice_type;
                                            currentClaimDetails.send_receipt = latest_res.send_receipt;
                                            currentClaimDetails.token = latest_res.token;
                                            currentClaimDetails.collectedAmount = (1 * latest_res.amount_collected).toFixed(2);
                                            currentClaimDetails.balanceAmount = (1 * latest_res.invoice_total - 1 * latest_res.amount_collected).toFixed(2);                                             

                                            currentClaimDetails.amount = ( 1 * latest_res.invoice_total ).toFixed(2);;


                                                let download =false;
                                                if (latest_res.department.allow_permits == 1) 
                                                download =true;
                                                else if (latest_res.department.allow_permits == 2) 
                                                 download =false;
                                                else if (latest_res.send_certificate == 0) 
                                                 download =false;
                                                else if (latest_res.send_certificate == 1) 
                                                 download =true; 

                                            //console.log(download);

                                            currentClaimDetails.download = download;    
                                            currentClaimDetails.alert = 'Thank you for your payment #'+latest_res.display_id;
                                            currentClaimDetails.status = latest_res.status;
                                            this.setState({claimDetails:currentClaimDetails});
                                            //window.clearInterval(timer);
                                        //}
                                  });
                              //if (++x === 5) {
                                        //window.clearInterval(timer);
                                        //currentClaimDetails.alert = 'Failed to update the invoice status#';
                                        //this.setState({claimDetails:currentClaimDetails});
                                       //window.clearInterval(timer);
                              //}
                          }, 6000);
                          //}, 3000);

                      }
                  currentClaimDetails.invoiceValid = true;
                  currentClaimDetails.status = invoiceData.status;
                  this.setState({claimDetails:currentClaimDetails});

               }


      }
    })
    .catch(function (error) {
      //console.log(error);
    });
  };
  /*
  This will handle the JSX Rendering
  */
  render ()
  {
    return (
      <Layout>
      <div className="custom-container">
              <div className="payment-wrap">
                  <div className="logo"><Link to="/"><img src={big_logo} alt=""/></Link></div>
                  <div className="pay-method">
                      <h1>{this.state.claimDetails.departmentName}</h1>
                      {! this.state.claimDetails.status ? <LoadingSpinner />:""}
                      <h3>Invoice Amount : ${this.state.claimDetails.amount}</h3>
                      { /*(this.state.claimDetails.collectedAmount > 0 ) && <h3>Total Amount Collected: ${this.state.claimDetails.collectedAmount}</h3>*/}
                      { /*( this.state.claimDetails.balanceAmount > 0 && this.state.claimDetails.collectedAmount > 0 ) && <h3>Balance Amount : ${this.state.claimDetails.balanceAmount}</h3>*/}
                      <div className="pay-option mail-check">
                          <div className="title">{this.state.claimDetails.alert}</div>
                          <div className="content">

                          {
                                     
                                    (this.state.claimDetails.invoice_type == 1 && this.state.claimDetails.send_receipt ==1)? 
                                    <p> 
                                    <a target="_blank" href={`${constants.BASERECEIPT+this.state.claimDetails.token}/printable-receipt`} >Download your Receipt (pdf)</a>
                                    </p>
                                    :null 
                          }

                          {
                                (this.state.claimDetails.invoice_type == 1 && this.state.claimDetails.download  && this.state.claimDetails.status == 'Paid' )? 
                                <p> 
                                <a target="_blank" href={`${constants.BASERECEIPT+this.state.claimDetails.token}/printable-certificate`} >Download your Fire Inspection Permit (pdf)</a>
                               </p>
                               :null 
                          }

                          {
                            ( this.state.claimDetails.invoice_type != 1 && this.state.claimDetails.download) &&
                             <p>
                                   <Link target="_blank" to=  {`/payrecepit/${this.props.match.params.id}/print/`}>
                                            Print/Download
                                   </Link>    Receipt.
                              </p>
                          }
                              <p>Click <Link to="/">
                                       here
                              </Link> to make a new payment.</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </Layout>
     );
  };
}
export default PaymentMethods;
