import React,{Component} from 'react';
import big_logo from '../../Assets/Images/recovery-hub-payment.png';
import Layout from '../Layout/Layout';
import { Link } from 'react-router-dom';
class NoMatch extends Component{
  /*
  This will home button click
  */
  handleHomeClick = ()=>{
    this.props.history.push('/');

  }
  render ()
  {
      return (

        <Layout>
        <div className="custom-container">
              <div className="payment-wrap">
                  <div className="logo"><Link to="/"><img src={big_logo} alt=""/></Link></div>
                  <div className="error">
                      <h1>404</h1>
                      <div className="error-wrap">
                          <h3>Oops! A page went missing.</h3>
                          <button onClick={this.handleHomeClick} type="button" className="btn">
                                    Go to Home page
                          </button>
                      </div>
                  </div>
              </div>
          </div>
          </Layout>
      );
  };
}
export default NoMatch;
